
import bus from '../assets/images/bus.jpeg'
import brand from '../assets/images/brand1.jpg'
import tv from '../assets/images/television1.jpg'
import out from '../assets/images/outdoor.jpg'
import mall from '../assets/images/mall.jpeg'

const busArr=["Full Bus Branding","Bus Back Panel","Bus Side Wraps"]
const outArr=["Unipoles","Hoardings/Billboards","Mini Hoardings","Bus Shelters","Centre Median","LED Hoardings","Traffic Police Barricades","Traffic Awareness Boards","Digital Wall Wraps","Wall Painting"]
const mallArr=["Lift Branding","Standies/Kiosk","Escalator Branding","Drop Downs","LED Sign Boards"]
const eleArr=["NewsPaper Advertising","FM/Radio Advertising","Cable TV Advertising","Television Advertising","Digital Advertising"]
const brandArr=["Automobile Branding","Tricycle Branding","Temporary Boards","No parking Branding","Look Walkers"]
export const stories=[
    {
        title:"Doctors",
        count:"600",
        story:busArr,
        img:bus,
    },
    {
        title:"Doctors",
        count:"600",
        story:outArr,
        img:out,
    },
    {
        title:"Engineers",
        count:"2500",
        story:mallArr,
        img:mall,
    },
    {
        title:"State Ranks",
        count:"6",
        story:eleArr,
        img:tv,
    },
    {
        title:"District Ranks",
        count:"34",
        story:brandArr,
        img:brand,
    },
]
